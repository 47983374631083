import React from 'react'
import { Grid, Paper } from '@mui/material'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { ProductLine } from '../types/ProductLine'

const StyledProductLine = styled(Paper)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 1.1px;
  padding: 40px 24px 48px;
  position: relative;

  p {
    opacity: 0.8;
    text-align: left;
  }
`

const Name = styled.div`
  font-size: 20px;
`

const photoSize = css`
  display: block;
`

const img = css`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  height: 100%;
`

function ProductLineCard({ description, logo, name }: ProductLine) {
  return (
    <StyledProductLine>
      <Grid container spacing={2}>
        {logo && (
          <Grid css={photoSize} item xs={3}>
            <GatsbyImage
              key="name"
              alt={name}
              css={img}
              image={logo.gatsbyImageData}
            />
          </Grid>
        )}
        <Grid item xs={logo ? 9 : 12}>
          <Name>{name}</Name>
        </Grid>

        <Grid item xs={12}>
          <div
            dangerouslySetInnerHTML={{
              __html: description.childMarkdownRemark.html,
            }}
          />
        </Grid>
      </Grid>
    </StyledProductLine>
  )
}

export default ProductLineCard
