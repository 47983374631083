import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import AboutContainer from '../components/AboutContainer';
import Layout from '../components/layout';

const AboutPage = ({ location }) => (
  <StaticQuery
    query={graphql`
      query AboutPageQuery {
        contentfulPerson(contentful_id: { eq: "1FhmBm6sFEaDwDp9GCQzZi" }) {
          company
          bio {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        allContentfulAboutPage(limit: 1) {
          edges {
            node {
              testimonials {
                contentful_id
                quote {
                  childMarkdownRemark {
                    html
                  }
                }
                person {
                  firstName
                }
              }
              productLines {
                contentful_id
                description {
                  childMarkdownRemark {
                    html
                  }
                }
                name
                logo {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <Layout pageName='About'>
        <AboutContainer
          {...data.contentfulPerson}
          products={data.allContentfulAboutPage.edges[0].node.productLines}
          testimonials={data.allContentfulAboutPage.edges[0].node.testimonials}
        />
      </Layout>
    )}
  />
);

export default AboutPage;
