import React from 'react';
import { Grid, Paper } from '@mui/material';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { mq } from '../breakpoints';
import Divider from '../assets/divider.svg';
import ProductLineCard from './ProductLineCard';
import TestimonialCard from './TestimonialCard';
import { Testimonial } from '../types/Testimonial';
import { ProductLine } from '../types/ProductLine';
import { Markdown } from '../types/Markdown';

const StyledPaper = styled(Paper)`
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  letter-spacing: 1.1px;
  padding: 24px;
  position: relative;

  p {
    opacity: 0.8;
    text-align: left;
  }
`;

const H1 = styled.h1`
  font-family: 'Tangerine', cursive;
  margin-top: 0;
`;

const img = css`
  border-radius: 12px;
  float: right;
  height: 120px;
  width: 120px;

  ${mq.sm(css`
    height: 200px;
    margin-left: 8px;
    width: 200px;
  `)}
`;

const DividerWrapper = styled.div`
  margin: 0 auto 32px;
  max-width: 500px;
  position: relative;
  text-align: center;

  ${mq.md(css`
    margin: 32px auto 48px;
  `)}
`;

const divider = css`
  fill: #638b8c;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  display: block;
  margin: 0 auto;
`;

const Text = styled.div`
  align-items: center;
  bottom: 0;
  display: flex;
  font-family: 'Tangerine', cursive;
  font-size: 32px;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
`;

interface PropTypes {
  bio: Markdown;
  company: string;
  image: any;
  products: ProductLine[];
  testimonials: Testimonial[];
}

function AboutContainer({ bio, company, image, products, testimonials }: PropTypes) {
  return (
    <Grid container spacing={8}>
      <Grid item xs={12}>
        <StyledPaper>
          <GatsbyImage css={img} alt={company} image={image.gatsbyImageData} />
          <H1>{company}</H1>
          <div dangerouslySetInnerHTML={{ __html: bio.childMarkdownRemark.html }} />
        </StyledPaper>
      </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent='space-evenly' spacing={2}>
          {testimonials.map(({ contentful_id, person, quote }) => (
            <Grid key={contentful_id} item xs={12} sm={6}>
              <TestimonialCard person={person} quote={quote} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DividerWrapper>
          <Divider css={divider} height={120} width={294} />
          <Text>Product Lines</Text>
        </DividerWrapper>
      </Grid>
      {products.map((product) => (
        <Grid key={product.contentful_id} item xs={12}>
          <ProductLineCard {...product} />
        </Grid>
      ))}
    </Grid>
  );
}

export default AboutContainer;
